export const env = {
  development: {
    API_URL: ' https://api.kunplatform.com/api/v1',
    BASE_URL: ' https://api.kunplatform.com/api/v1',
  },
  production: {
    API_URL: ' https://api.kunplatform.com/api/v1',
    BASE_URL: ' https://api.kunplatform.com/api/v1',
  },
  test: {
    API_URL: ' https://api.kunplatform.com/api/v1',
    BASE_URL: ' https://api.kunplatform.com/api/v1',
  },
};
export const KUN_URL = 'http://157.175.94.158:3001/';

export const WEBSOCKET_URL = 'wss://api.kunplatform.com/api/v1/ws';
